import React, { Component } from "react";
import axios from "axios";
import { configData } from "./configData/configData";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActions, Grid } from "@mui/material";
import "./ListaForm.css";
import ScegliBottoneListaForm from "./ScegliBottoneListaForm";
import logoEuroform from "./image/logoEuroform.png";
import "./font/Ubuntu-Bold.ttf";
import "./font/Ubuntu-Light.ttf";
import { Backdrop, CircularProgress } from "@mui/material";

// Configurazione header axios
axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");

class ListaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dati: [],  // Lista dei dati dei form
      bottone: {},  // Mappa per l'accesso rapido allo stato dei bottoni
      loading: true,  // Stato di caricamento
    };
  }

  // Funzione asincrona per caricare i dati dei form
  async loadData() {
    const token = localStorage.getItem("token");

    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

      try {
        // Richiesta per ottenere i dati dei form
        const res = await axios.get(`${configData.API_BASE}/api/forms/`);
        
        // Creazione delle promesse per recuperare lo stato del bottone per ogni form
        const formCompilatoPromises = res.data.map(async (form) => {
          const button = await axios.get(
            `${configData.API_BASE}/api/forms/submit/risposta/${form.id}/${localStorage.getItem(
              "id"
            )}/exist`
          );
          return { id: form.id, b: button.data };
        });

        // Attendi il completamento di tutte le promesse
        const formCompilato = await Promise.all(formCompilatoPromises);

        // Crea la mappa bottone {id: stato_bottone} per un accesso rapido
        const bottoneMap = formCompilato.reduce((acc, curr) => {
          acc[curr.id] = curr.b;
          return acc;
        }, {});

        // Aggiorna lo stato con i dati ottenuti
        this.setState({
          dati: res.data,
          bottone: bottoneMap,
          loading: false,
        });
      } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        this.setState({ loading: false }); // Gestione dell'errore
      }
    } else {
      // Se il token non esiste, azzera il valore del header
      axios.defaults.headers.common["Authorization"] = null;
    }
  }

  // Metodo che viene chiamato quando il componente è montato
  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={logoEuroform}
            alt="Logo Euroform"
            width={"20%"}
            height={"20%"}
            style={{ marginTop: "35px" }}
          />
          <h1
            style={{
              marginTop: "20px",
              fontFamily: "Ubuntu-Black",
              color: "#1d659c",
            }}
          >
            Lista Form Disponibili
          </h1>

          {/* Mappa dei form con chiave univoca */}
          {this.state.dati.map((form) => (
            <Grid item md={2.4} key={form.id}>
              <Card className="card" style={{ fontFamily: "Ubuntu-Light" }}>
                <CardContent>
                  <b>{form.nomeProgetto}</b>
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  <ScegliBottoneListaForm
                    formId={form.id}
                    isTrue={this.state.bottone[form.id]} // Ottieni stato bottone dalla mappa
                  />
                </CardActions>
              </Card>
            </Grid>
          ))}

          {/* Mostra il caricamento mentre i dati sono in fase di caricamento */}
          <Backdrop sx={{ color: "#ffffff", zIndex: 999 }} open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </div>
    );
  }
}

export default ListaForm;
