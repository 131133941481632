import React, { Component } from "react";
import * as PropTypes from "prop-types";
import FormRisposta from "./FormRisposta";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActions } from "@mui/material";
import axios from "axios";
import { configData } from "./configData/configData";
import { useParams } from "react-router-dom";
import './Risposta.css';

// Funzione per aggiungere i parametri ai componenti
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

// Configurazione delle intestazioni per axios
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
axios.defaults.headers.common['Content-Type'] = localStorage.getItem('id');

FormRisposta.propTypes = {
    uiSchema: PropTypes.any
};

// Funzione per la stampa
function PrintElem(elem) {
    document.getElementById(elem).className = "printCardForm";
    window.print();
    document.getElementById(elem).className = "cardForm";
    return true;
}

// Classe Risposta
class Risposta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: {},
            uiSchema: {},
            formData: {}
        };
    }

    // Funzione per caricare i dati del form
    loadFormData(formId) {
        const token = localStorage.getItem('token');
        console.log("Token recuperato:", token);

        if (token) {
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

            // Recupero il JSON del form
          
            axios.get(`${configData.API_BASE}/api/forms/${formId}/blocked=true`)
                .then(res => {
                    try {
                       
                        const jsonSchemaStr = res.data.jsonschema;

                        // Sanifica il JSON prima del parsing
                        const sanitizedJsonSchemaStr = this.sanitizeJson(jsonSchemaStr);


                        // Parsing del JSON
                        const jsonSchema = JSON.parse(sanitizedJsonSchemaStr);
                        const uiSchema = JSON.parse(res.data.uischema);

                        // Imposta lo stato
                        this.setState({ schema: jsonSchema, uiSchema: uiSchema });
                    } catch (error) {
                        console.error("Errore durante il parsing del JSON:", error);
                    }
                })
                .catch(error => {
                    console.error("Errore nella richiesta per il form:", error);
                });
        } else {
            axios.defaults.headers.common['Authorization'] = null;
            console.warn("Token non presente");
        }
    }

    // Funzione di sanificazione del JSON
    sanitizeJson(jsonStr) {
        try {
           

            // Correggi sequenze di escape malformate
            // Cattura tutte le sequenze di escape errate e le corregge
            let sanitized = jsonStr;

            // Correggi tutte le sequenze \X che non sono seguite da un carattere valido
            sanitized = sanitized.replace(/\\([^u0-9])/g, '\\$1');  // Aggiusta escape generali non valide
            sanitized = sanitized.replace(/\\u([0-9a-fA-F]{4})/g, '\\u$1');  // Aggiusta sequenze \uXXXX

            // Ora riproviamo a fare il parsing
            try {
                const parsedJson = JSON.parse(sanitized);
                console.log("JSON valido!");
                return sanitized;
            } catch (error) {
                console.error("Errore nel parsing del JSON sanificato:", error);
                return jsonStr; // Restituiamo il JSON originale se il parsing fallisce
            }

        } catch (error) {
            console.error("Errore durante la sanificazione del JSON:", error);
            return jsonStr; // Se c'è un errore, restituiamo comunque il JSON originale
        }
    }

    // Funzione che viene chiamata quando il parametro formId cambia
 componentDidMount() {
    const { formId } = this.props.params;

    this.loadFormData(formId); // Carica i dati del form iniziali
}

componentDidUpdate(prevProps) {
    const { formId } = this.props.params;

    if (prevProps.params.formId !== formId) {
        this.loadFormData(formId);
    }
}


    render() {
        return (
            <div>
                <Card className="noPrint">
                    <CardActions>
                        <button onClick={() => PrintElem("form")}>Stampa</button>
                    </CardActions>
                </Card>

                <Card>
                    <CardContent className="cardForm" id="form">
                        <FormRisposta
                            schema={this.state.schema}
                            uiSchema={this.state.uiSchema}
                            chiave={this.getHeadings}
                            formData={this.state.formData}
                        />
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default withParams(Risposta);
