import { configData } from './configData/configData';
import * as React from 'react';									
import PropTypes from 'prop-types';
import {styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { AppBar, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, useTheme, DialogContentText, linkClasses } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CancelIcon from '@mui/icons-material/Cancel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search'
import { Select, MenuItem, Button,} from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import {useEffect} from "react";
import {useLocation, useParams, useNavigate} from "react-router-dom";
import Menu from '@mui/material/Menu';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AssignmentIcon from '@mui/icons-material/Assignment';
import XLSX from "sheetjs-style";
import * as fileSaver from "file-saver";										  
import ManageSearchIcon from '@mui/icons-material/ManageSearch';									   
import {Backdrop, CircularProgress} from "@mui/material";


axios.defaults.headers.common['Authorization']=localStorage.getItem('token');

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const mappaturaColonne = [
  ['form_submit_id','form_submit_id'],
  ["background","background"],
  ["cap","CAP"],
  ["tsanit","Tessera Sanitaria Allegato"],
  ["citta","Città"],
  ["codiceFiscale","Codice Fiscale"],
  ["cognome","Cognome"],
  ["curriculumVitae","Curriculum Vitae"],
  ["dataAttuale","Data Sottomissione Form"],
  ["dataEmissioneDocumentoEspatrio","Data Documento Espatrio"],
  ["dataNascita","Data di Nascita"],
  ["dataScadenzaDocumentoEspatrio","Data Scadenza Documento Espatrio"],
  ["documentoEspatrio","Documento Espatrio"],
  ["documentoEspatrioRilascio","Ente di Rilascio Documento Espatrio"],
  ["email","Indirizzo Mail"],
  ["identita","Carta di Identità"],
  ["indirizzo","Indirizzo"],
  ["luogoNascita","Luogo di Nascita"],
  ["maggiorenne","Maggiorenne"],
  ["nazionalita","Nazionalità"],
  ["nDocumentoEspatrio","Numero Documento Espatrio"],
  ["nome","Nome"],
  ["paeseDestinazione","Paese di Destinazione"],
  ["paeseNascita","Paese di Nascita"],
  ["paeseResidenza","Paese di Residenza"],
  ["telefonoCellulare","Numero di Telefono"],
  ["telefonoCellulareContatto","Numero di Telefono Contatto"],
  ["attoNotorietaCompilata","Atto di Notorietà compilato"],
  ["attualmente","Stato lavorativo attuale"],
  ["cognomeContatto","Cognome Contatto"],
  ["consenso","Consenso"],
  ["consensoPresaVisioneAccettata","Consenso di presa visione"],
  ["fototessera","Fototessera"],
  ["indirizzoContatto","Indirizzo Contatto"],
  ["interessi","Interessi"],
  ["isee","Modello ISEE"],
  ["linguaMadre","Lingua Madre"],
  ["nomeContatto","Nome Contatto"],
  ["parentelaContatto","Grado di Parentela Contatto"],
  ["provinciaNascita","Provincia di Nascita"],
  ["provinciaResidenza","Provincia di Residenza"],
  ["regioneNascita","Regione di Nascita"],
  ["regioneResidenza","Regione di Residenza"],
  ["scuolaBackground","Scuola"],
  ["ascolto-","Livello di ascolto della lingua "],
  ["certLingue","Certificazione Lingue"],
  ["competenzeInformatiche","Competenze Informatiche"],
  ["informazioniAggiuntive","Informazioni Aggiuntive"],
  ["interazione-","Livello Interazione con Lingua "],
  ["lettura-","Livello Lettura con Lingua "],
  ["orale-","Livello Orale con Lingua "],
  ["scritta-","Livello Scritto con Lingua "],
  ["titolo-","Lingua seconda "],
  ["studiSecondarie","Scuole secondarie II grado"],
  ["altreQualifiche","Altre Qualifiche"],
  ["softSkills","Soft Skills"],
  ["studiSecondarieDettagli","Voto/Anno di conseguimento Scuola secondarie II grado"],
  ["altreLingue","Altre Lingue"],
  ["anno-","Anno conseguimento Qualifica "],
  ["ascolto-","Livello Ascolto con Lingua "],
  ["durata-","Durata Corso per qualifica "],
  ["luogo-","Organizzazione di formazione qualifica "],
  ["titoloQualifica-","Corso/Qualifica "],
  ["studiSuperiori","Studi Superiori"],
  ["studiSuperioriDettagli","Voto/Anno di conseguimento Studi Superiori"],
  ["user_id","user_id"],
  ["sub_project_id","sub_project_id"],
  ["preferenzaSettore", "Preferenza Settore"], 
  ["candidatoMinoreOpportunita", "Candidato Minore Opportunità"],  
  ["studenteUniversitario", "Studente Universitario"],
  ["partecipazione", "Partecipazione precedente Erasmus"],
  ["Partenza", "Partenza pre-adesioni"],
  ["periodo", "Periodo partenza candidature"],
  ["copiaDiploma", "Copia del diploma"],
  ["copiaUnilav", "Copia Unilav"],
  ["DID", "Copia DID"],
  ["attoCorso", "Attestato Corso"],
  ["certificazione", "Certificato Min. Opport."]
];

let ordine = [
  'form_submit_id',
  'user_id',
  'sub_project_id',
  'Nome',
  'Cognome',
  'Codice Fiscale',
  'Tessera Sanitaria Allegato',
  'Fototessera',
  'Paese di Residenza',
  'Regione di Residenza',
  'Provincia di Residenza',
  'Città',
  'Indirizzo',
  'CAP',
  'Nazionalità',
  'Paese di Nascita',
  'Regione di Nascita',
  'Provincia di Nascita',
  'Luogo di Nascita',
  'Data di Nascita',
  'Indirizzo Mail',
  'Numero di Telefono',
  'Stato lavorativo attuale',
  'Maggiorenne',
  'Data Sottomissione Form',
  'Paese di Destinazione',
  'Documento Espatrio',
  'Numero Documento Espatrio',
  'Data Documento Espatrio',
  'Data Scadenza Documento Espatrio',
  'Ente di Rilascio Documento Espatrio',
  'Carta di Identità',
  'Nome Contatto',
  'Cognome Contatto',
  'Indirizzo Contatto',
  'Numero di Telefono Contatto',
  'Grado di Parentela Contatto',
  'Scuola',
  'Curriculum Vitae',
  'Modello ISEE',
  'Lingua Madre',
  'Preferenza Settore',  
  'Candidato Minore Opportunità',  
  'Studente Universitario',  
  'Partecipazione precedente Erasmus',
  'Partenza pre-adesioni',
  "Periodo partenza candidature",
  "Copia del diploma",
  "Copia Unilav",
  "Copia DID",
  "Attestato Corso",
  "Certificato Min. Opport."
];

let ordineLingue = ['Lingua seconda ',
  'Livello Scritto con Lingua ',
  'Livello Interazione con Lingua ',
  'Livello Lettura con Lingua ',
  'Livello Ascolto con Lingua ',
  'Livello Orale con Lingua '
];

let ordineQualifiche=['Corso/Qualifica ',
  'Durata Corso per qualifica ',
  'Anno conseguimento Qualifica ',
  'Organizzazione di formazione qualifica '
];

let ordine2 = ['Altre Lingue',
'altreQualifiche',
'Certificazione Lingue'
];

const ordine3 = ['Scuole secondarie II grado',
  'background',
  'Voto/Anno di conseguimento Scuola secondarie II grado',
  'Studi Superiori',
  'Voto/Anno di conseguimento Studi Superiori',
  'Competenze Informatiche',
  'Soft Skills',
  'Interessi',
  'Informazioni Aggiuntive',
  'Consenso di presa visione',
  'Atto di Notorietà compilato'
];



export default function EnhancedTable() {

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {columns.map((headCell) => (
          <TableCell
          key={headCell.id}
          align="right"
          padding={headCell.disablePadding ? 'none' : 'normal'}
          sortDirection={orderBy === headCell.id ? order : false}
          sx={headCell.label==="Cognome"?{
            cursor: 'default', 
            position: 'sticky',
            //overflow: 'hidden',
            //textOverflow: 'ellipsis',
            //whiteSpace: 'nowrap',
            left: 0,
            zIndex: 999,
            backgroundColor : 'white'
          }:null}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const EnhancedTableToolbar = (props) => {
  
  const { numSelected,selezionati, rowCount, onSelectAllClick, row, tableCellIdentity} = props;
  const [selectedSubProject, setSelectedSubProject] = React.useState('');
  const [subProjects, setSubProjects] = React.useState([]);
  const [assignConfirmationOpen, setAssignConfirmationOpen] = React.useState(false);
  const [assignSuccessMessage, setAssignSuccessMessage] = React.useState('');
  const [unassignConfirmationOpen, setUnassignConfirmationOpen] = React.useState(false);
  const [unassignSuccessMessage, setUnassignSuccessMessage] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [deleteRowIndexMultiple, setDeleteRowIndexMultiple] = React.useState(null);
  const state = useLocation();
  let { formId } = useParams();
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
      fetchSubProjects();
    }, []);

    const fetchSubProjects = () => {
      var token = localStorage.getItem('token');
  
      if (token) {
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
  
        let apiUrl = configData.API_BASE + "/api/subProject";

      if (formId !== null && formId !== undefined) {
          apiUrl += `/form/${formId}`;
        }
        axios.get(apiUrl)
          .then(response => { 
            if (Array.isArray(response.data)) {
              const subProjectList = response.data.map(subProject => ({
                id: subProject.idSubProject,
                citta: subProject.cittaDest,
                progetto: subProject.idProgAppartenenza,
                dateInizio: moment(subProject.inizioMobilita).format('DD-MM-YYYY'),
                dateFine: moment(subProject.fineMobilita).format('DD-MM-YYYY')
              }));
              setSubProjects(subProjectList);
            } else {
              console.error('Invalid API response:', response.data);
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    };
  
    const handleSelectChange = (event) => {
      setSelectedSubProject(event.target.value);
    };
  
    const [data, setData] = React.useState([]);
    const [headers, setHeaders] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
  
    const fileTypeExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const exportToExcel = async() => {
        var mappaLabel = new Map(mappaturaColonne);

        let ordineCompleto = [];
        ordineCompleto = ordineCompleto.concat(ordine);
        var token=localStorage.getItem('token');

        if(token){
            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            let apiUrl = configData.API_BASE + "/api/forms/submit/dataform/submit/form";

      if (formId !== null && formId !== undefined) {
        apiUrl += `/${formId}`;
        }
            axios.get(apiUrl).then(
                res=>{
                    const json=res.data.records;
                    let newJson=[];
                    //console.log(json);
                    json.forEach(element => {
                      var newElement = {};
                      Object.keys(element).forEach(oldKey => {
                        //console.log(oldKey)
                        //console.log(oldKey.replace(oldKey, mappaLabel.get(oldKey)))
                        var newKey = oldKey;
                        if (oldKey.indexOf("-") >= 0) {
                          mappaLabel.forEach((val, k) => {
                            newKey = newKey.replace(k,val);
                          })
                        } else {
                          newKey = oldKey.replace(oldKey, mappaLabel.get(oldKey))
                        }
                        newElement[newKey] = element[oldKey];
                      })
                      newJson.push(newElement);
                    })

                    let lingue = new Map;
                    let qualifiche = new Map;
                    Object.keys(newJson[0]).forEach(element => {
                        if(new RegExp("^Lingua seconda \\d","g").test(element)){
                            let temp = new RegExp("\\d","g").exec(element);
                            let orderNlingua=0;

                            ordineLingue.forEach(elementoLingua => {
                                lingue.set((temp*10)+orderNlingua,elementoLingua+temp);
                                orderNlingua++;
                            })
                        }
                    })
                    let lingue1 = new Map([...lingue.entries()].sort((a, b) => a[0] - b[0]));
                    let lingueArray = [];
                    for (let value of lingue1.values()) {
                        lingueArray.push(value);
                    }
                    ordineCompleto = ordineCompleto.concat(lingueArray);

                    Object.keys(newJson[0]).forEach(element => {
                        if(new RegExp("^Corso/Qualifica \\d","g").test(element)){
                            let temp = new RegExp("\\d","g").exec(element);
                            let orderNQualifica=0;

                            ordineQualifiche.forEach(elementoQualifiche => {
                                qualifiche.set((temp*10)+orderNQualifica,elementoQualifiche+temp);
                                orderNQualifica++;
                            })
                        }
                    })
                    let qualifiche1 = new Map([...qualifiche.entries()].sort((a, b) => a[0] - b[0]));
                    let qualificheArray = [];
                    for (let value of qualifiche1.values()) {
                        qualificheArray.push(value);
                    }
                    ordineCompleto = ordineCompleto.concat(qualificheArray);
                    ordineCompleto = ordineCompleto.concat(ordine2);

                    //console.log("ordine completo", ordineCompleto);

                    //console.log("newJson", newJson);

                    let lastJson=[];
                    newJson.forEach(element => {
                        var nuovoElement = {};
                        Object.keys(element).forEach(chiave => {
                            if(ordineCompleto.indexOf(chiave)!=-1){
                                nuovoElement[chiave] = element[chiave];
                                //console.log(chiave);
                            }
                        })
                        lastJson.push(nuovoElement);
                    })


                    //console.log("lastJson", lastJson);

                    let jsonExcel= JSON.stringify(lastJson);

                    const ws = XLSX.utils.json_to_sheet(JSON.parse(jsonExcel), {header: ordineCompleto});


                    const wb = {Sheets: {'data':ws}, SheetNames: ['data']};


                    const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type: 'array'});
                    const data= new Blob ([excelBuffer],{type:fileTypeExcel});



                    fileSaver.saveAs(data, 'ExportExcel.xlsx');
                    setLoading(false);
                }
            )
            }else{
                axios.defaults.headers.common['Authorization']=null;
            }


    }

    const exportErasmus = async() => {
        var token=localStorage.getItem('token');

        if(selezionati.length==0){
            alert("Nessun utente selezionato");
        }else if(token) {

            setLoading(true);

            var stringaSelezionati = "";
            selezionati.forEach(s=>{
                stringaSelezionati = stringaSelezionati.concat("&id="+s.toString());
            })

            axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

            axios({
              url: configData.API_BASE+"/api/forms/submit/downloadModello?name=modelloErasmus"+stringaSelezionati+"&nomeProgetto="+state.nome, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', "Modello Erasmus.docx"); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                alert("Errore nel download")

                }
            );

            setLoading(false);
            //window.open(configData.API_BASE+"/api/forms/submit/downloadModello?name=modelloErasmus"+stringaSelezionati, '_blank');

        }else{
            axios.defaults.headers.common['Authorization']=null;
        }



    }


const exportLearningAgreement = async () => {
    var token = localStorage.getItem('token');

    if (selezionati.length === 0) {
        alert("Nessun utente selezionato");
    } else if (token) {
        setLoading(true);

        var stringaSelezionati = "";
        selezionati.forEach(s => {
            stringaSelezionati = stringaSelezionati.concat("&id=" + s.toString());
        });

        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

        const nomeProgetto = state && state.state && state.state.nome ? state.state.nome : ""; // Check if state and state.state.nome exist

        axios({
            url: configData.API_BASE + "/api/forms/submit/downloadModelloZip?name=learning-agreement" + stringaSelezionati + "&nomeProgetto=" + nomeProgetto,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', "learning_agreement.zip");
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            alert("Errore nel download");
        });

    } else {
        axios.defaults.headers.common['Authorization'] = null;
    }
}


 function stampa() {
    var token=localStorage.getItem('token');


    if(token){

      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
      axios.get(configData.API_BASE+"/api/forms/submit/dataform/submit/form/").then(
          res=>{
            //console.log("res",res);
            const post=res.data;
            //console.log("post",post);
            var cols =[];
            cols.push({
              id: "form_submit_id",
              numeric: false,
              disablePadding: true,
              label: "ID",
            })
            Object.keys(post.records[0]).forEach((key) => {
              if(key != "form_submit_id" && key != "user_id"){
              cols.push({
                id: key,
                  numeric: false,
                  disablePadding: true,
                  label: key,
              })}
            })
            setHeaders(cols); 
            //console.log("cols",cols);

            setData(post.records);
            var csv="data:text/csv;charset=utf-8,"; 
            var colArr = [];
            cols.forEach((col) => {
              colArr.push(col.label);
            }) 
            //csv+=colArr.join(",");
            csv+="\n";
            post.records.forEach((row) => {
              var rowArr = [];
              rowArr.push(row.form_submit_id)
              Object.keys(row).forEach((key) => {
                if(key != "form_submit_id" && key != "user_id") {

                  rowArr.push(row[key]);
                }
              })
              csv+=rowArr.join(",")
              csv+="\n";
            });

            //console.log("data",post.records);

            var encodedUri = encodeURI(csv);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "my_data.csv");
            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
      }
      )
    }else{
      axios.defaults.headers.common['Authorization']=null;
    }
 } 

  const csvReport={
    filename: 'Report.csv',
    headers: headers,
    data: data
  };
  
  const handleAssignClick = () => {
    if (selectedSubProject && selezionati.length > 0) {
      setAssignConfirmationOpen(true); 
    } else {
      setMessage('Per favore, seleziona un Sotto-Progetto e un FormSubmit');
    }
  };

  const handleUnassignClick = () => {
    if (selezionati.length > 0) {
      setUnassignConfirmationOpen(true); 
    } else {
      setMessage('Per favore, seleziona un FormSubmit');
    }
  };
 

  const confirmAssign = () => {
    axios.put(configData.API_BASE + '/api/forms/submit/assign-subproject/' + selectedSubProject,selezionati)
      .then((response) => {
        setAssignSuccessMessage(response.data); 
        setAssignConfirmationOpen(false); 
       applicaFiltri();
        window.alert("I candidati con id :" + selezionati + " sono stati assegnati con successo al SottoProgetto con ID " + selectedSubProject + ".");
      })
      .catch((error) => {
        console.error('Error assigning FormSubmits:', error);
        setMessage('Error assigning FormSubmits');
      });
  };

  const confirmUnassign = () => {
    axios.put(configData.API_BASE + '/api/forms/submit/unassign-subproject',selezionati)
      .then((response) => {
        setUnassignSuccessMessage(response.data); 
        setUnassignConfirmationOpen(false); 
        applicaFiltri();
        window.alert("I candidati con id :" + selezionati + " sono stati deselezionati.");
      })
      .catch((error) => {
        console.error('Error unassigning FormSubmits:', error);
        setMessage('Error unassigning FormSubmits');
      });
  };
  
  const handleSubProjectChange = (event) => {
    setSelectedSubProject(event.target.value);
  };

  const handleDeleteMultiple = (selected) => {
    handleDeleteConfirmationMultiple(selected);
  };

  const handleDeleteConfirmationMultiple = (selected) => {
    setDeleteRowIndexMultiple(selected);
  }

  const handleDeleteCancelMultiple = () => {
    setDeleteRowIndexMultiple(null);
  };

  const handleDeleteSelected = () => {
    //console.log(selected)
      axios.delete(configData.API_BASE+'/api/forms/submit/delete/', {data : selezionati})
        .then(response => {
          //console.log(response.data);
          setSelected([]);
          applicaFiltri();
          window.alert("I candidati con id :" + selezionati + " sono stati cancellati con successo.");
          setDeleteRowIndexMultiple(null);
        })
        .catch(error => {
          //console.error(error);
          alert("Errore imprevisto durante il processo di delete delle righe selezionate");
      });
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 0 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Checkbox
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          'aria-label': 'select all desserts',
        }}
        sx = {{
          position: 'sticky',
          //overflow: 'hidden',
          //textOverflow: 'ellipsis',
          //whiteSpace: 'nowrap',
          left: 0,
          zIndex: 1,
        }}
      />
      {numSelected > 0 ? (
        <Typography 
        color="inherit"
        variant="subtitle1"
        component="div"
        sx = {{
          position: 'sticky',
          //overflow: 'hidden',
          //textOverflow: 'ellipsis',
          //whiteSpace: 'nowrap',
          left: 50,
          zIndex: 1,
        }}
      >
          {numSelected} selezionato/i
          <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        color="primary"
      >
       Download Selezionati <FileDownloadIcon />
      </Button>
      <StyledMenu 
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {handleClose(); exportErasmus();}} disableRipple>
          <FileDownloadIcon   style ={{color: "rgb(214 14 14 / 60%)"}} />
          Scarica il bando Erasmus
        </MenuItem>
        <MenuItem onClick={() => {handleClose(); exportLearningAgreement();}} disableRipple>
          <FileDownloadIcon  style ={{color: "rgb(214 14 14 / 60%)"} }/>
            Scarica il Learning Agreement
        </MenuItem> 
        <MenuItem onClick={() => { handleClose(); /*stampa();*/}} disableRipple >
          <FileDownloadIcon />
            <del>Esporta tabella in formato CSV </del>
        </MenuItem>  
        <MenuItem onClick={() => { handleClose(); exportToExcel();}} disableRipple>
          <FileDownloadIcon  style ={{color: "rgb(0 177 0 / 60%)"}}/>
          Esporta tabella in formato Excel
        </MenuItem>
        </StyledMenu>
        <Backdrop sx={{color:'#ffffff', zIndex:999}} open={loading} ><CircularProgress color="inherit"/></Backdrop>

        <Button variant="contained" onClick={handleDeleteMultiple} color="secondary" sx={{left: 50}}>
        Elimina Selezionati <DeleteIcon/>
          </Button>
          <Select value={selectedSubProject} onChange={handleSelectChange} style={{ marginLeft: '30px', marginRight: '30px' }}>
      <MenuItem value="">
        <em>Seleziona un Sotto Progetto</em>
      </MenuItem>
      {subProjects.map((subProject) => (
        <MenuItem key={subProject.id} value={subProject.id}>
          {subProject.id} : {subProject.progetto.nomeProject} - {subProject.citta.descrizione} dal {subProject.dateInizio} al {subProject.dateFine}
        </MenuItem>
      ))}
    </Select>
    <Button
      variant="contained"
      onClick={handleAssignClick}
      color="primary" 
    >
      Assign <AssignmentIcon/>
    </Button>
    <Button
      variant="contained"
      onClick={handleUnassignClick}
      color="secondary"
      sx={{ marginLeft: '30px' }}
    >
      UnAssign <CancelIcon />
    </Button>
    {assignSuccessMessage && (
  <script>
    {`alert("${assignSuccessMessage}");`}
  </script>
)}

{unassignSuccessMessage && (
  <script>
    {`alert("${unassignSuccessMessage}");`}
  </script>
)}
        </Typography>
      ) : (
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
          sx = {{
          position: 'sticky',
          //overflow: 'hidden',
          //textOverflow: 'ellipsis',
          //whiteSpace: 'nowrap',
          display : 'flex',
          left: 50,
          zIndex: 1,
        }}
        >
         <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        color="primary"
      >
       Download <FileDownloadIcon />
      </Button>
      <StyledMenu 
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {handleClose(); exportErasmus();}} disableRipple>
          <FileDownloadIcon   style ={{color: "rgb(214 14 14 / 60%)"}} />
          Scarica il bando Erasmus
        </MenuItem>
        <MenuItem onClick={() => {handleClose(); exportLearningAgreement();}} disableRipple>
          <FileDownloadIcon  style ={{color: "rgb(214 14 14 / 60%)"} }/>
            Scarica il Learning Agreement
        </MenuItem> 
        <MenuItem onClick={() => { handleClose(); /*stampa();*/}} disableRipple >
          <FileDownloadIcon />
            <del>Esporta tabella in formato CSV </del>
        </MenuItem>  
        <MenuItem onClick={() => { handleClose(); exportToExcel();}} disableRipple>
          <FileDownloadIcon  style ={{color: "rgb(0 177 0 / 60%)"}}/>
          Esporta tabella in formato Excel
        </MenuItem>
        </StyledMenu>
        <Backdrop sx={{color:'#ffffff', zIndex:999}} open={loading} ><CircularProgress color="inherit"/></Backdrop>

        </Typography>
      )}
      <Dialog open={deleteRowIndexMultiple !== null} onClose={handleDeleteCancelMultiple}>
         <DialogTitle>Elimina le righe</DialogTitle>
         <DialogContent>
           <DialogContentText>
             Sei sicuro di voler eliminare le righe selezionate?
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button onClick={handleDeleteCancelMultiple} color="primary">
             Annulla
           </Button>
           <Button onClick={handleDeleteSelected} color="primary">
             Conferma
           </Button>
         </DialogActions>
       </Dialog>
       <Dialog open={assignConfirmationOpen} onClose={() => setAssignConfirmationOpen(false)}>
  <DialogTitle>Conferma Assegnazione</DialogTitle>
  <DialogContent>
    <DialogContentText>
        Sei sicuro di voler assegnare gli elementi selezionati al SottoProgetto scelto?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setAssignConfirmationOpen(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={confirmAssign} color="primary">
      Confirm
    </Button>
  </DialogActions>
</Dialog>

<Dialog open={unassignConfirmationOpen} onClose={() => setUnassignConfirmationOpen(false)}>
  <DialogTitle>Conferma Unassegnazione</DialogTitle>
  <DialogContent>
    <DialogContentText>
        Sei sicuro di voler unassegnare gli elementi selezionati?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setUnassignConfirmationOpen(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={confirmUnassign} color="primary">
      Confirm
    </Button>
  </DialogActions>
</Dialog>
    </Toolbar>
         
  
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function linkDownload(link){
  window.location.href=link;
}


//export default function EnhancedTable() {


  let { formId } = useParams();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [subProjects, setSubProjects] = React.useState([]);
  const [selectedSubProject, setSelectedSubProject] = React.useState('');
  const [orderDirection, setOrderDirection] = React.useState("desc");
  const [listFormSubmitExport, setListFormSubmitExport] = React.useState({
    records: [],
    totalElement: 0
  });
  const [listFormSubmit, setListFormSubmit] = React.useState({
    records: [],
    totalElement: 0
  });

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [ordineColonne, setOrdineColonne] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteRowIndexMultiple, setDeleteRowIndexMultiple] = React.useState(null);
  const [deleteRowIndex, setDeleteRowIndex] = React.useState(null);
  const[params, setParams]=React.useState({});
  const [loading, setLoading] = React.useState(true);
  const { state } = useLocation();

  
  
  function risposta(user_id, formSubmitId) {
   
    // Salva l'ID dell'utente in localStorage
    localStorage.setItem('id', user_id); 


    var token = localStorage.getItem('token');
    let url = "/formRisposta/";

    if (token) {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

        axios.get(configData.API_BASE + '/api/forms/submit/formId/' + formSubmitId)
            .then(response => {
                const formId = response.data;
            
                if (formId) {
                    url += formId + "/" + user_id;
             
                } else {
                    url += user_id;
                  
                }

                // Reindirizzamento alla nuova URL
                window.location.href = url;
            })
            .catch(error => {
                console.error('Errore durante il recupero dell\'ID del formSubmit:', error);
            });
    } else {
        console.log('Token non trovato');
    }
}








  useEffect(() => {
    fetchSubProjects();
  }, []);

  const fetchSubProjects = () => {
    var token = localStorage.getItem('token');

    if (token) {
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

      let apiUrl = configData.API_BASE + "/api/subProject";

    if (formId !== null && formId !== undefined) {
        apiUrl += `/form/${formId}`;
      }
      axios.get(apiUrl)
        .then(response => { 
          if (Array.isArray(response.data)) {
            const subProjectList = response.data.map(subProject => ({
              id: subProject.idSubProject,
              citta: subProject.cittaDest,
              progetto: subProject.idProgAppartenenza,
              dateInizio: moment(subProject.inizioMobilita).format('DD-MM-YYYY'),
              dateFine: moment(subProject.fineMobilita).format('DD-MM-YYYY')
            }));
            setSubProjects(subProjectList);
          } else {
            console.error('Invalid API response:', response.data);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleChange = (event, field) => { 
    // Ricerca sulla barra globale
    const newValue = event.target.value;
    
    setParams((prevParams) => ({
      ...prevParams,
      [field]: newValue,
    }));
    
    const queryParams = Object.entries(params)
      .filter(([key, value]) => value.length !== 0)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
      let apiUrl = configData.API_BASE + "/api/forms/submit/dataform/submit/form";
  
      if (formId !== null && formId !== undefined) {
        apiUrl += `/${formId}`;
      }
    axios.get(apiUrl += `?${queryParams}`).then(
    res => {
          const mappaLabel = new Map(mappaturaColonne);
          const json = res.data.records;
          let newJson = [];
          json.forEach(element => {
            const newElement = {};
            Object.keys(element).forEach(oldKey => {
              let newKey = oldKey;
              if (oldKey.indexOf("-") >= 0) {
                mappaLabel.forEach((val, k) => {
                  newKey = newKey.replace(k, val);
                });
              } else {
                newKey = oldKey.replace(oldKey, mappaLabel.get(oldKey));
              }
              newElement[newKey] = element[oldKey];
            });
            newJson.push(newElement);
          });
          let lastJson = [];
          newJson.forEach(element => {
            const nuovoElement = {};
            const orderElement = {};
            Object.keys(element).forEach(chiave => {
              if (ordineColonne.indexOf(chiave) !== -1) {
                nuovoElement[chiave] = element[chiave];
              }
            });
            Object.values(ordineColonne).forEach(chiaveOrdinata => {
              orderElement[chiaveOrdinata] = nuovoElement[chiaveOrdinata];
            });
            lastJson.push(orderElement);
          });
          setRows(lastJson);
          setLoading(false);
        })
        .catch(error => {
          console.error('Errore durante la richiesta API', error);
        });
    } else {
      axios.defaults.headers.common['Authorization'] = null;
    }
  };
  
  const navigate = useNavigate();

  const handlePage = (idSubProgettoPass) => {
    if (idSubProgettoPass) {
      //console.log(idSubProgettoPass);
      navigate("/subProject/" + idSubProgettoPass);
    } else {
      window.alert("Candidato non è assegnato a nessun sottoprogetto.");
    }
  };

  const handleChangeColumn = (event, field) => { 
    //ricerca per colonna
    var stringa =event.target.value;
    let newSelected ='';
    newSelected = newSelected.concat(stringa);
    //console.log(stringa);
    var par = {...params, [field]: stringa}
    setParams(par);
    //console.log("params",params)
    //console.log("par",par)
    var listaParametri = [];
    Object.keys(par).forEach(key => {
      if(par[key].length!=0)
        listaParametri.push(key + "=" + par[key]);
    })
    console.log(listaParametri.join("&"));
    var stringaDaInserire=listaParametri.join("&");
    var token=localStorage.getItem('token');

    if(token){
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
      let apiUrl = configData.API_BASE + "/api/forms/submit/dataform/submit/form";
  
  if (formId !== null && formId !== undefined) {
    apiUrl += `/${formId}`; 
  }
  axios.get(apiUrl += `?${stringaDaInserire}`).then(
    res => {

            //console.log(res);
            //const post=res.data;
            var mappaLabel = new Map(mappaturaColonne);

            const json=res.data.records;

            let newJson=[];
            //console.log(json);
            json.forEach(element => {
              var newElement = {};
              Object.keys(element).forEach(oldKey => {
                //console.log(oldKey)
                //console.log(oldKey.replace(oldKey, mappaLabel.get(oldKey)))
                var newKey = oldKey;
                if (oldKey.indexOf("-") >= 0) {
                  mappaLabel.forEach((val, k) => {
                    newKey = newKey.replace(k,val);
                  })
                } else {
                  newKey = oldKey.replace(oldKey, mappaLabel.get(oldKey))
                }
                newElement[newKey] = element[oldKey];
              })
              newJson.push(newElement);
            });

            let lastJson=[];

            newJson.forEach(element => {
              var nuovoElement = {};
              var orderElement = {};
              Object.keys(element).forEach(chiave => {
                    if(ordineColonne.indexOf(chiave)!=-1){
                      nuovoElement[chiave] = element[chiave];
                    }
                  }
              )
              Object.values(ordineColonne).forEach(chiaveOrdinata => {
                orderElement[chiaveOrdinata] = nuovoElement[chiaveOrdinata];
              })
              lastJson.push(orderElement);
            });
            //console.log(post);
            /*
            var cols =[];
            cols.push({
              id: "form_submit_id",
              numeric: false,
              disablePadding: true,
              label: "ID",
            })
            Object.keys(post.records[0]).forEach((key) => {
              if(key != "form_submit_id" && key != "user_id"){
              cols.push({
                id: key,
                  numeric: false,
                  disablePadding: true,
                  label: key,
              })}
            })
            setColumns(cols);*/
            setRows(lastJson);
            setLoading(false);
      }
          
      )
    }else{
      axios.defaults.headers.common['Authorization']=null;
    }
  }



  useEffect(() => {

    //tabella iniziale
    var token=localStorage.getItem('token');

    if(token){
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
      let apiUrl = configData.API_BASE + "/api/forms/submit/dataform/submit/form";

    if (formId !== null && formId !== undefined) {
      apiUrl += `/${formId}`;
    }

      axios.get(apiUrl).then(
          res=>{


            var mappaLabel = new Map(mappaturaColonne);
            let ordineCompleto = [];
            ordineCompleto = ordineCompleto.concat(ordine);
            const json=res.data.records;
            let newJson=[];
            json.forEach(element => {
              var newElement = {};
              Object.keys(element).forEach(oldKey => {
                //console.log(oldKey.replace(oldKey, mappaLabel.get(oldKey)))
                var newKey = oldKey;
                if (oldKey.indexOf("-") >= 0) {
                  mappaLabel.forEach((val, k) => {
                    newKey = newKey.replace(k,val);
                  })
                } else {
                  newKey = oldKey.replace(oldKey, mappaLabel.get(oldKey))
                }
                newElement[newKey] = element[oldKey];
              })
              newJson.push(newElement);
            })

            let lingue = new Map;
            let qualifiche = new Map;
            Object.keys(newJson[0]).forEach(element => {
              if(new RegExp("^Lingua seconda \\d","g").test(element)){
                let temp = new RegExp("\\d","g").exec(element);
                let orderNlingua=0;

                ordineLingue.forEach(elementoLingua => {
                  lingue.set((temp*10)+orderNlingua,elementoLingua+temp);
                  orderNlingua++;
                })
              }
            })
            let lingue1 = new Map([...lingue.entries()].sort((a, b) => a[0] - b[0]));
            let lingueArray = [];
            for (let value of lingue1.values()) {
              lingueArray.push(value);
            }
            ordineCompleto = ordineCompleto.concat(lingueArray);



            Object.keys(newJson[0]).forEach(element => {
              if(new RegExp("^Corso/Qualifica \\d","g").test(element)){
                let temp = new RegExp("\\d","g").exec(element);
                let orderNQualifica=0;

                ordineQualifiche.forEach(elementoQualifiche => {
                  qualifiche.set((temp*10)+orderNQualifica,elementoQualifiche+temp);
                  orderNQualifica++;
                })
              }
            })
            let qualifiche1 = new Map([...qualifiche.entries()].sort((a, b) => a[0] - b[0]));
            let qualificheArray = [];
            for (let value of qualifiche1.values()) {
              qualificheArray.push(value);
            }
            ordineCompleto = ordineCompleto.concat(qualificheArray);
            ordineCompleto = ordineCompleto.concat(ordine2);
            ordineCompleto = ordineCompleto.concat(ordine3);

            let lastJson=[];

            newJson.forEach(element => {
              var nuovoElement = {};
              var orderElement = {};
              Object.keys(element).forEach(chiave => {
                if(ordineCompleto.indexOf(chiave)!=-1){
                  nuovoElement[chiave] = element[chiave];
                }
              }
              )
              Object.values(ordineCompleto).forEach(chiaveOrdinata => {
                orderElement[chiaveOrdinata] = nuovoElement[chiaveOrdinata];
              })
              lastJson.push(orderElement);
            })
            let cols =[];


            /*
            cols.push({
              id: "form_submit_id",
              numeric: false,
              disablePadding: true,
              label: "ID",
            })
            Object.keys(post.records[0]).forEach((key) => {
              if(key != "form_submit_id" && key != "user_id"){
              cols.push({
                id: key,
                  numeric: false,
                  disablePadding: true,
                  label: key,
              })}
            })
             */



            /*
            cols.push({
              id: "form_submit_id",
              numeric: false,
              disablePadding: true,
              label: "IDD",
            })
*/


            Object.values(ordineCompleto).forEach((k) => {
              if(k=="form_submit_id"){
                cols.push({
                  id: "form_submit_id",
                  numeric: false,
                  disablePadding: true,
                  label: "ID",
                });
              } else if (k === "user_id") {
                cols.push({
                  id: "user_id",
                  numeric: false,
                  disablePadding: true,
                  label: "User Id", 
                });
              } else if (k === "sub_project_id") {
                cols.push({
                  id: "sub_project_id",
                  numeric: false,
                  disablePadding: true,
                  label: "Sotto Progetto Id", 
                });
              }else{
                cols.push({
                  id: k,
                  numeric: false,
                  disablePadding: true,
                  label: k,
                })
              }

            })

            setOrdineColonne(ordineCompleto);
            setColumns(cols);
            setRows(lastJson);
            setLoading(false);
      }
          
      ).catch(error => {
        console.log("Error:", error.response);
      });
    }else{
      axios.defaults.headers.common['Authorization']=null;
    }
  },[])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleSelectAllClick = (event, rowCount, selectedCount) => {
    if (event && (selectedCount != rowCount)) {
      const newSelected = rows.map((n) => n.form_submit_id);
      //console.log("sono selezionati " + newSelected);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
    //console.log([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected,id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    //console.log("Sono selezionati alcuni" + newSelected);
    setSelected(newSelected);
    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };


  const applicaFiltri = () => {
    const queryParams = Object.entries(params)
      .filter(([key, value]) => value.length !== 0)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
      let apiUrl = configData.API_BASE + "/api/forms/submit/dataform/submit/form";
  
      if (formId !== null && formId !== undefined) {
        apiUrl += `/${formId}`;
      }
    axios.get(apiUrl += `?${queryParams}`).then(
    res => {
          const mappaLabel = new Map(mappaturaColonne);
          const json = res.data.records;
          let newJson = [];
          json.forEach(element => {
            const newElement = {};
            Object.keys(element).forEach(oldKey => {
              let newKey = oldKey;
              if (oldKey.indexOf("-") >= 0) {
                mappaLabel.forEach((val, k) => {
                  newKey = newKey.replace(k, val);
                });
              } else {
                newKey = oldKey.replace(oldKey, mappaLabel.get(oldKey));
              }
              newElement[newKey] = element[oldKey];
            });
            newJson.push(newElement);
          });
          let lastJson = [];
          newJson.forEach(element => {
            const nuovoElement = {};
            const orderElement = {};
            Object.keys(element).forEach(chiave => {
              if (ordineColonne.indexOf(chiave) !== -1) {
                nuovoElement[chiave] = element[chiave];
              }
            });
            Object.values(ordineColonne).forEach(chiaveOrdinata => {
              orderElement[chiaveOrdinata] = nuovoElement[chiaveOrdinata];
            });
            lastJson.push(orderElement);
          });
          setRows(lastJson);
          setLoading(false);
        })
        .catch(error => {
          console.error('Erreur lors de la requête API', error);
        });
    } else {
      axios.defaults.headers.common['Authorization'] = null;
    }
  };

const [filterFormSubmit, setFilterFormSubmit] = React.useState({
  form_submit_id: null,
  user_id: null,
  sub_project_id: null,
  nome: null,
  cognome: null,
  codiceFiscale: null,
  cfiscale: null,
  fototessera: null,
  paeseResidenza: null,
  regioneResidenza: null,
  provinciaResidenza: null,
  citta: null,
  indirizzo: null,
  cap: null,
  nazionalita: null,
  paeseNascita: null,
  regioneNascita: null,
  provinciaNascita: null,
  luogoNascita: null,
  dataNascita: null,
  email: null,
  telefonoCellulare: null,
  attualmente: null,
  maggiorenne: null,
  dataAttuale: null,
  paeseDestinazione: null,
  greenPass: null,
  documentoEspatrio: null,
  nDocumentoEspatrio: null,
  dataEmissioneDocumentoEspatrio: null,
  dataScadenzaDocumentoEspatrio: null,
  documentoEspatrioRilascio: null,
  identita: null,
  nomeContatto: null,
  cognomeContatto: null,
  indirizzoContatto: null,
  telefonoCellulareContatto: null,
  parentelaContatto: null,
  scuolaBackground: null,
  curriculumVitae: null,
  isee: null,
  linguaMadre: null,
  titolo: null,
  scritta: null,
  interazione: null,
  lettura: null,
  ascolto: null,
  orale: null,
  altreLingue: null,
  titoloQualifica: null,
  durata: null,
  anno: null,
  luogo: null,
  altreQualifiche: null,
  certLingue: null,
  studiSecondarie: null,
  background: null,
  studiSecondarieDettagli: null,
  studiSuperiori: null,
  studiSuperioriDettagli: null,
  competenzeInformatiche: null,
  softSkills: null,
  interessi: null,
  informazioniAggiuntive: null,
  consensoPresaVisione: null,
  consensoPresaVisioneAccettata: null,
  consenso: null,
  attoNotorietaCompilata: null,
  page: 0,
  maxElementPage: 10,
});

const handleDeleteConfirm = () => {
  if (deleteRowIndex !== null) {
    const id = rows[deleteRowIndex].form_submit_id
    //const nome = rows[deleteRowIndex].nome
    axios.delete(configData.API_BASE+'/api/forms/submit/'+id)
      .then(response => {
        //handleChange();
        applicaFiltri();
        window.alert("Il candidato con id :" + id + " e stato cancellato con successo.");
      setDeleteRowIndex(null);
      })
      .catch(error => {
        //console.error(error);
        alert("Errore imprevisto durante il processo di delete delle righe selezionate");
    });
    //console.log(rows[deleteRowIndex].idProject);
  }
};


const handleDeleteConfirmationMultiple = (selected) => {
  setDeleteRowIndexMultiple(selected);
}
const handleDelete = (id) => {
  handleDeleteConfirmation(id);
};

const handleDeleteConfirmation = (index) => {
  setDeleteRowIndex(index);
}

const handleDeleteCancel = () => {
  setDeleteRowIndex(null);
};
const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const tableCellIdentity = (content) => {
    if(content.toString().indexOf(";base64,") != -1){
      const fileName = content.split(';')[1].split('=')[1];
      return <a download={fileName} href={content} style={{backgroundColor: "red"}}>Scarica File</a>
    }
    return content;
  };

  const downloadRegex= new RegExp("download\\/\\d+\\/\\w+","g");
  return (
    <Box sx={{ width: '90%' }} style={{marginTop:"20px", marginLeft:"5%"}}>
      <Box mb={4} />
      <Box ml={2} mr={2}>
      <div>
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    <Accordion sx={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          <strong>Filtri di ricerca </strong>
          <SearchIcon icon="search" />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
  <Grid container spacing={4}>
    <Grid item xs={12} sm={3}>
      <TextField label="Nome" name="nome" variant="standard" value={params.nome} onChange={(e) => setParams({ ...params, nome: e.target.value })} sx={{ minWidth: '78%' }} />
    </Grid>
    <Grid item xs={12} sm={3}>
      <TextField label="Cognome" name="Cognome" variant="standard" value={params.cognome} onChange={(e) => setParams({ ...params, cognome: e.target.value })} sx={{ minWidth: '78%' }} />
    </Grid>
    <Grid item xs={12} sm={3}>
      <TextField label="Codice Fiscale" name="codiceFiscale" variant="standard" value={params.codiceFiscale} onChange={(e) => setParams({ ...params, codiceFiscale: e.target.value })} sx={{ minWidth: '78%' }} />
    </Grid>
    <Grid item xs={12} sm={3}>
      <TextField label="Scuole secondarie II grado" name="studiSecondarie" variant="standard" value={params.studiSecondarie} onChange={(e) => setParams({ ...params, studiSecondarie: e.target.value })} sx={{ minWidth: '78%' }} />
    </Grid>
    <Grid item xs={12} sm={3}>
      <InputLabel>Sotto Progetto</InputLabel>
      <Select
    label="SubProject"
    value={selectedSubProject}
    onChange={(e) => {
      setSelectedSubProject(e.target.value);
      handleChange(e, 'sub_project_id');
    }}
    sx={{ minWidth: '78%' }}
  >
    <MenuItem value="NULL">Nessuno</MenuItem>
    {subProjects.map((subProject) => (
      
      <MenuItem key={subProject.id} value={subProject.id}>
        {subProject.id} : {subProject.progetto.nomeProject} - {subProject.citta.descrizione} dal {subProject.dateInizio} al {subProject.dateFine}
      </MenuItem>
    ))}
  </Select>
      </Grid>
    <Grid item xs={12}>
      <br></br>
      <Button variant="contained" color="primary" onClick={applicaFiltri}>
        Filtra
      </Button>
    </Grid>
  </Grid>
</AccordionDetails>
    </Accordion>
  </Box>
</div>
    <Box mb={4}/>
      <Paper sx={{ width: '100%', mb: 2 }} style={{
        boxShadow: "1px 3px 5px grey",
        borderRadius: "6px",
        padding: '1em',}}>
        <EnhancedTableToolbar rowCount={rows.length} onSelectAllClick={() => handleSelectAllClick(rows, rows.length, selected.length)} handleChange={handleChange} numSelected={selected.length} selezionati={selected}></EnhancedTableToolbar>
        <TableContainer style={{ maxHeight: "70vh" }}>
          <Table
            sx={{minWidth: 750}}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
              onChangeColumn={handleChangeColumn}
            />
            <TableBody> 
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.form_submit_id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.form_submit_id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                      <div style={{ display: 'flex'}}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.form_submit_id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                       <Button variant="outlined" color="primary" onClick={() => {
    console.log("user_id:", row.user_id); // Verifica il valore di user_id
    console.log("formSubmitId:", row.form_submit_id); // Verifica il valore di formSubmitId
    risposta(row.user_id, row.form_submit_id); // Passaggio dei parametri
}}>
    Dettagli <VisibilityIcon />
</Button>

                        <Button onClick={() => handleDelete(index)} size="small" style={{marginLeft : '0px'}}><DeleteIcon color="error" /></Button>
                        <Button onClick={() => handlePage(row.sub_project_id)} size="small" style={{marginLeft : '0px'}}><ManageSearchIcon color="action" /></Button>
                      </div>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="right"
                      >
                        {row.form_submit_id}
                      </TableCell>
                      <TableCell align="right">{row.user_id}</TableCell>
                      <TableCell align="right">{row.sub_project_id}</TableCell>
                      {Object.keys(row).map((key) => {
                        if (key != "form_submit_id" && key!="user_id" && key!="sub_project_id") {
                          if (row[key] == null) {
                            row[key] = "";
                          }
                          if (row[key].indexOf("/download/")>=0){
                            return (<TableCell align="right">
                              <Button variant="contained" component="label" color="primary" onClick={() => linkDownload(row[key])}>
                                Download
                              </Button>
                              </TableCell>)
                          } else if (key === "Cognome") {
                            return (
                              <TableCell
                              align={key.align}
                              sx={{
                                  cursor: 'default', // Imposta il cursore sulla colonna 'nome'
                                  position: 'sticky',
                                  //overflow: 'hidden',
                                  //textOverflow: 'ellipsis',
                                  //whiteSpace: 'nowrap',
                                  left: 0,
                                  zIndex: 1,
                                  backgroundColor : 'white'
                                }}
          >
                              {row[key]}
                              </TableCell>
                            );
                          }
                          else {
                              return (<TableCell align="right">{tableCellIdentity(row[key])}</TableCell>)
                            } 
                          }
                        }
                      )}
                      {/*<TableCell align="right">{row.username}</TableCell>
                      <TableCell align="right">{row.nome}</TableCell>
                      <TableCell align="right">{row.cognome}</TableCell>
                      <TableCell align="right">{row.età}</TableCell>
                      <TableCell align="right">{row.documento}</TableCell>
                      <TableCell align="right">{row.numeroDocumento}</TableCell>*/}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Backdrop sx={{color:'#ffffff', zIndex:999}} open={loading} ><CircularProgress color="inherit"/></Backdrop>

    </Box>

    <Dialog open={deleteRowIndex !== null} onClose={handleDeleteCancel}>
        <DialogTitle>Elimina riga</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare questa riga?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
  </Box>
  );
}