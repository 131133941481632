import React, { Component } from "react";
import {CardActions, Grid} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import {configData} from "./configData/configData";
import fileImportato from "./resources/DICHIARAZIONE_SOSTITUTIVA_DELL'ATTO_DI_NOTORIETA.pdf";


class AllegatiScaricabiliForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileAllegato:null,
        }
    };

    loadData() {
        /*console.log(this.props.nome + " al path: ./resources/" + this.props.path);
        let string = "./resources/" + this.props.path;
        let fileTemp = this.state.fileAllegato;
        const module = await import(string);
        this.setState({...this.state, fileAllegato: module});
        console.log(string);
         */

    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <div>
                &nbsp;
                <Grid container spacing={2} direction="column" justifyContent="center"><Grid item md={2.4}>
                        <Card>
                            <CardContent>
                                <b>{this.props.nome}</b>
                            </CardContent>
                            <CardActions>
                                <a>{this.props.descrizione}</a>
                                <a href={fileImportato} download>Scarica</a>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                &nbsp;
            </div>);
    }
}

export default AllegatiScaricabiliForm;