import 'bootstrap/dist/css/bootstrap.min.css';
import './TestForm.css';
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import axios from "axios";
import * as React from "react";
import { useEffect } from "react";
import { configData } from "./configData/configData";
import { useParams } from "react-router-dom";  // Importa useParams

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

function FormRisposta(props) {
    const componentRef = React.useRef(null);
    const [formData, setFormData] = React.useState({});
    const { formId } = useParams();  // Ottieni l'ID dinamico del form dalla rotta

    useEffect(() => {
        const token = localStorage.getItem('token');
        
        if (token) {
            // Usa il token per l'autorizzazione
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

       

            // Usa formId dinamico nella richiesta
            axios.get(`${configData.API_BASE}/api/forms/submit/dettagli/${formId}/${localStorage.getItem('id')}`)
                .then(res => {
                    const post = res.data;

           
                    setFormData(post);
                });            
        } else {
            axios.defaults.headers.common['Authorization'] = null;
        }
    }, [formId]); // Aggiungi formId come dipendenza per ricaricare quando cambia

    return (
        <div>
            <Form
                schema={props.schema}
                uiSchema={props.uiSchema}
                formData={formData} // Usa il formData locale direttamente
                validator={validator}
                showErrorList={false}
                disabled={true}
                readonly
            />
        </div>
    );
}

export default FormRisposta;
