import './App.css';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import Login from './components/login';
import React from 'react';
import Registrati from './components/registrati';
import ResetPassword from './components/resetPassword'
import Domanda from "./Domanda";
import Risposta from "./Risposta";
import Button from '@mui/material/Button';
import Tabella from './tabella';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import ListaForm from "./ListaForm";
import ListaFormBackoffice from "./ListaFormBackoffice";
import {configData} from "./configData/configData";
import VisualizzaDownload from "./VisualizzaDownload";
import ListPartner from "./ListPartner";
import ProjectTable from "./ProjectTable";
import SubProjectTable from "./SubProjectTable";



const ruolo = localStorage.getItem("ruolo");
const token = localStorage.getItem("token");

const pages = ['Login', 'Registrati', 'Lista dei Candidati', 'Lista dei Form disponibili', 'Lista dei Form', 'Esci', 'Partner', 'Progetti', 'Sotto-Progetti'];
const link = [configData.SITE_URL+'/login', configData.SITE_URL+'/registrami',configData.SITE_URL+'/tab',configData.SITE_URL+'/listaFormBackOffice', configData.SITE_URL+'/listaFormBackOffice', configData.SITE_URL+'/login']
const view = [token == null, token == null, token != null && ruolo != null && ruolo != 'ROLE_ADMIN', ruolo == 'ROLE_ADMIN', token != null];


function App() {

  function Home() {
    localStorage.getItem("token") != null ? document.location.href = configData.SITE_URL+"/listaForm" : document.location.href = configData.SITE_URL+"/login";
  }

  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  function esci(pagina) {
    if (pagina == "Esci") {
      localStorage.clear();
    }
  }

  return (
    <Router>
      <AppBar position="static" style={{backgroundColor: '#1d659c'}}>
      
        <Container maxWidth="xl" >
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
              <MenuIcon />
              </IconButton>

              
              
              <Menu
                disableScrollLock={true}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'left',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >

                {pages.map((page) => (
                  <MenuItem key={page} hidden={view[pages.indexOf(page)] == false} onClick={() => { document.location.href = link[pages.indexOf(page)]; esci(page) }} >
                    <Typography textAlign="center"> {page}</Typography>
                  </MenuItem>
                ))}
              </Menu>

            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
            </Typography>
           
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} >
              <ul className="noPrint" variant="h6" component="div" style={{ marginTop: "10px", marginLeft: "20%", margin: "auto" }} sx={{ flexGrow: 1 }} onClick={handleCloseNavMenu}>
                {token == null && <Button onClick={() => { document.location.href = configData.SITE_URL+"/login"; }} style={{ color: 'white' }} >{pages[0]} </Button>}

                {token == null && <Button onClick={() => { document.location.href = configData.SITE_URL+"/registrami"; }} style={{ color: 'white' }}> {pages[1]}</Button>}
                  {/*const pages = ['Login', 'Registrati', 'Lista dei Candidati', 'Lista dei Form disponibili', 'Lista dei Form', 'Esci', 'Partner', 'Progetti', 'Sotto-Progetti'];*/}

                {token != null && ruolo != null && ruolo != "ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/listaForm"; }} style={{ color: 'white' }}  > {pages[3]}</Button>}
                  {/*{token != null && ruolo != null && ruolo != "ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/partner"; }} style={{ color: 'white' }}  > {pages[6]}</Button>}
                {token != null && ruolo != null && ruolo != "ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/project"; }} style={{ color: 'white' }}  > {pages[7]}</Button>}
                {token != null && ruolo != null && ruolo != "ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/subProject"; }} style={{ color: 'white' }}  > {pages[8]}</Button>}*/}

                {/* {ruolo=="ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/formRisposta/1/1"; }} style={{ color: 'white' }} >{pages[3]}</Button>}*/}

                {ruolo == "ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/tab"; }} style={{ color: 'white' }} > {pages[2]}</Button>}
                {ruolo == "ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/listaFormBackoffice"; }} style={{ color: 'white' }} > {pages[4]}</Button>}
                {ruolo == "ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/partner"; }} style={{ color: 'white' }} > {pages[6]}</Button>}
                {ruolo == "ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/project"; }} style={{ color: 'white' }} > {pages[7]}</Button>}
                {ruolo == "ROLE_ADMIN" && <Button onClick={() => { document.location.href = configData.SITE_URL+"/subProject"; }} style={{ color: 'white' }} > {pages[8]}</Button>}

                {token != null && <Button onClick={() => { localStorage.clear(); document.location.href = configData.SITE_URL+"/login"; }} style={{ color: 'white' }}> {pages[5]}</Button>}

              </ul>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>


      <Routes>
       {token==null ? ( 
       <><Route href="#login" path='/login' element={<Login />} ></Route><Route href="#registrami" path='/registrami' element={<Registrati />} ></Route></>
       ):(
        <Route/>
       )}
        {token != null && ruolo != null && ruolo != "ROLE_ADMIN" ? (
          <><Route href="#form" path='/form/:formId' element={<Domanda />}></Route><Route href="#listaForm" path='/listaForm' element={<ListaForm />}></Route></>
        ) : (
          <Route></Route>
        )}
        {ruolo == "ROLE_ADMIN" ? (
          <><Route href="#tab" path='/tab' element={<Tabella />}></Route>
          <Route href="#tab" path='/tab/:formId' element={<Tabella />}></Route>
          <Route href="#formRisposta" path='/formRisposta/:formId/:userId' element={<Risposta />}></Route>
          <Route href="#listaFormBackoffice" path='/listaFormBackoffice' element={<ListaFormBackoffice />}></Route>
          <Route href="#partner" path='/partner' element={<ListPartner />}></Route>
          <Route href="#project" path='/project' element={<ProjectTable />}></Route>
          <Route href="#project" path='/project/:idSubProjectPassato' element={<ProjectTable />}></Route>
          <Route href="#subProject" path='/subProject' element={<SubProjectTable />}></Route>
          <Route href="#subProject" path='/subProject/:idProjectPassato' element={<SubProjectTable />}></Route>
          <Route href="#download" path='/download/:formSubmitId/:metaKey' element={<VisualizzaDownload />}></Route></>
          
        ) : (
          <Route></Route>
        )}
        <Route href="#formRisposta" path='/formRisposta/:formId' element={<Risposta />}></Route>


        <Route href="#formRisposta" path='/formRisposta/:formId/:userId' element={<Risposta />}></Route>
        <Route href="#resetPassword" path='/resetPassword/:token' element={<ResetPassword />}></Route>
        <Route href="#" path='/' element={<Home />}></Route>
       
      </Routes>

    </Router>
  );

}

export default App;