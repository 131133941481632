import React, { Component } from "react";
import * as PropTypes from "prop-types";
import FormDomanda from "./FormDomanda";
import AllegatiScaricabiliForm from "./AllegatiScaricabiliForm";
import Card from "@mui/material/Card";
import './Domanda.css';
import CardContent from "@mui/material/CardContent";
import { ButtonGroup, CardMedia, Button } from "@mui/material";
import mainLogo from "./image/logoErasmus.png";
import ueDisclaimer from "./image/UE_disclaimer.PNG";
import { configData } from "./configData/configData";
import { useParams } from "react-router-dom";
import { CircularProgress, Backdrop } from "@mui/material";
import fileImportato from "./resources/DICHIARAZIONE_SOSTITUTIVA_DELL'ATTO_DI_NOTORIETA.pdf"; // Importa il PDF
import axios from 'axios'; 
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

FormDomanda.propTypes = { uiSchema: PropTypes.any };

class Domanda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: {},
      uiSchema: {},
      formData: {},
      currentPage: 1,
      pages: 1,
      cssCustom: "",
      errors: 0,
      allegati: [],
      loading: true,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
  
      // Fetch form schema and page details
      axios.get(`${configData.API_BASE}/api/forms/${this.props.params.formId}`)
        .then(res => {
          let jsonSchema = {};
          let uiSchema = {};
  
          // Rimuovere e correggere i caratteri problematici
          const cleanedJsonSchema = res.data.jsonschema.replace(/\\/g, '\\\\'); // Escapa tutte le barre rovesciate
          const cleanedUiSchema = res.data.uischema.replace(/\\/g, '\\\\');
  
          try {
            jsonSchema = JSON.parse(cleanedJsonSchema);
          } catch (error) {
            console.error('Error parsing jsonSchema', error);
          }
  
          try {
            uiSchema = JSON.parse(cleanedUiSchema);
          } catch (error) {
            console.error('Error parsing uiSchema', error);
          }
  
          // Impostazione della data attuale, se presente nel jsonSchema
          const dateNow = new Date().toISOString().slice(0, 10);
          if (jsonSchema.properties && jsonSchema.properties.dataAttuale !== undefined) {
            jsonSchema.properties.dataAttuale.default = dateNow;
          }
  
          // Creazione della logica per il CSS personalizzato per il cambio pagina
          let css = "";
          for (let i = 1; i <= res.data.pages; i++) {
            if (i !== this.state.currentPage) {
              css += `.f${i} { display: none; }\n.MuiGrid-item:has(.f${i}) { display: none; }\n`;
            }
          }
  
          if (this.state.currentPage !== res.data.pages) {
            css += `.submitButton { visibility: hidden; }`;
          }
          
  
          // Aggiorna lo stato con i dati recuperati
          this.setState({
            schema: jsonSchema,
            uiSchema: uiSchema,
            pages: res.data.pages,
            cssCustom: css
          });
  
          // Fetch attachments
          axios.get(`${configData.API_BASE}/api/formsAttach/${this.props.params.formId}`)
            .then(response => {
              const allegatiTemp = [...this.state.allegati, ...response.data];
              this.setState({ allegati: allegatiTemp, loading: false });
            })
            .catch(error => {
              console.error("Errore nel recupero degli allegati", error);
            });
  
        })
        .catch(error => {
          console.error("Errore nella richiesta API", error);
          this.setState({ loading: false });
        });
    } else {
      // Se il token non esiste, rimuovi l'header di autorizzazione
      axios.defaults.headers.common['Authorization'] = null;
    }
  }

  handleCallback = (newFormData) => {
    this.setState({ formData: newFormData });
  }

  render() {
    return (
      <Card style={{ position: 'absolute', left: '50%', transform: 'translate(-50%)', boxShadow: "1px 3px 5px grey", borderRadius: "6px", padding: '1em' }}>
        <CardMedia component="img" image={mainLogo} alt="logo" />
        <CardContent>
          <style id="cssPagine">{this.state.cssCustom}</style>
  
          <ButtonGroup>
            <Button variant="contained" className='backButton' style={{ display: this.state.currentPage === 1 ? "none" : "block" }} onClick={() => { this.previousPage() }}>
              previous page
            </Button>
            <Button variant="contained" className='nextButton' style={{ display: this.state.currentPage === this.state.pages ? "none" : "block" }} onClick={() => { this.nextPage() }}>
              next page
            </Button>
          </ButtonGroup>
  
          <FormDomanda
            setErrors={this.setErrors}
            schema={this.state.schema}
            uiSchema={this.state.uiSchema}
            formData={this.state.formData}
            formId={this.props.params.formId}
            aggiornaStato={this.handleCallback}
          />
  
          {/* Bottone per scaricare il PDF - posizionato dopo l'ultima domanda del form */}
          <a href={fileImportato} download="DICHIARAZIONE_SOSTITUTIVA_DELL'ATTO_DI_NOTORIETA.pdf">
            <Button variant="contained" style={{ marginTop: '20px' }}>Scarica la Dichiarazione sostitutiva</Button>
          </a>
  <br></br> <br></br>
          {this.state.currentPage === 1 && (
            <div>
              <h4>Dichiaro</h4>
              <ul>
                <li>Di aver preso visione del bando e accettarne il contenuto</li>
                <li>Di essere in possesso dei requisiti formali richiesti dal Bando per l'ammissione alla selezione</li>
                <li>Di non aver riportato condanne penali e di non avere precedenti penali pendenti (in caso contrario, segnalare quali)</li>
                <li>Che i dati riportati nel form sono veritieri</li>
                <li>Non usufruire di altri finanziamenti per soggiorni all'estero, erogati su fondi dell'Unione Europea</li>
              </ul>
            </div>
          )}
  
          {/* I bottoni di navigazione o il tasto submit verranno dopo */}
          <ButtonGroup>
            <Button variant="contained" className='backButton' style={{ display: this.state.currentPage === 1 ? "none" : "block" }} onClick={() => { this.previousPage() }}>
              previous page
            </Button>
            <Button variant="contained" className='nextButton' style={{ display: this.state.currentPage === this.state.pages ? "none" : "block" }} onClick={() => { this.nextPage() }}>
              next page
            </Button>
          </ButtonGroup>
        </CardContent>
        <CardMedia component="img" image={ueDisclaimer} alt="logo" />
        <Backdrop sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Card>
    );
  }
  
  
  
}

export default withParams(Domanda);
